const names = {
  aiee: "AI Essentials for Educators",
  cca: "C# Coding Apprentice",
  ccs: "C# Coding Specialist",
  ciais: "The Coding-in-AI Specialist",
  cpcs: "C Coding Specialist",
  cppcs: "C++ Coding Specialist",
  dncs: ".NET Coding Specialist",
  hccs: "HTML CSS Coding Specialist",
  hcca: "HTML CSS Coding Apprentice",
  java: "JAVA Specialist",
  jca: "Java Coding Apprentice",
  jcs: "Java Coding Specialist",
  jsca: "JavaScript Coding Apprentice",
  jscs: "JavaScript Coding Specialist",
  pca: "Python Coding Apprentice",
  pcs: "Python Coding Specialist",
  phpcs: "PHP Coding Specialist",
  sqlca: "SQL Coding Apprentice",
  sqlcs: "SQL Coding Specialist",
  tscs: "T-SQL Coding Specialist",
  wca: "WordPress Certified Administrator",
  wcd: "Word Certified Developer",
  wce: "WordPress Certified Editor",
  wes: "WordPress Elementor Specialist",
  wws: "WordPress WooCommerce Specialist",
  wordcs: "Word Certified Specialist"
}

export default function getExamCodeName(input: string) { // TODO: replace with names from queries
  return names[input.toLowerCase()]
}
